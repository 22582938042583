import React, { useState, useEffect } from 'react';
import Toggle from './Toggle';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
const TermsandPrivacy = () => {
  return (
    <section className='py-12 md:py-24 bg-gray-900 min-h-screen'>
      <div className='container px-4 mx-auto lg:flex gap-2'>
        <div className='w-full lg:w-1/2 px-4 mb-8 lg:mb-0'>
          <TermsAndConditions />
        </div>
        <div className='w-full lg:w-1/2 px-4 mb-8 lg:mb-0'>
          <PrivacyPolicy />
        </div>
      </div>
    </section>
  );
};

export default TermsandPrivacy;
