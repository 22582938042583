import React from 'react';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useTheme } from '..';

export const Modal = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <button
        type='button'
        onClick={openModal}
        className='group relative mb-4 xs:mr-8 flex w-full xs:w-auto items-center justify-center h-12 px-4 text-center text-base text-teal-800 font-bold bg-white rounded-lg transition duration-300'
      >
        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-700 animate-pulse group-hover:ring-0 transition duration-300' />
        {title}
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-10 overflow-y-auto'
          onClose={closeModal}
        >
          <div className='min-h-screen max-h-screen px-4 py-12 text-center flex flex-col items-center justify-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-out duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className=''>
                <Dialog.Overlay className='fixed inset-0 bg-gradient-to-br from-gray-800 to-gray-1000 opacity-80' />
              </div>
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='flex-1 w-full prose dark:prose-dark max-w-3xl p-6 overflow-hidden text-left align-middle transition-all transform bg-gray-600 shadow-xl rounded-xl inline-flex flex-col max-h-full'>
                {children}
                <button
                  type='button'
                  className='flex-0 mb-4 xs:mb-0 flex w-full xs:w-auto items-center justify-center h-12 px-4 text-center text-base text-teal-800 font-bold bg-white rounded-lg transition duration-300'
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
