import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function VerifyEmail({ verify }) {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section className='py-12 md:py-24 bg-gray-900'>
        <div className='container px-4 mx-auto'>
          <div className='relative pb-16 border-b border-yellowGreen-900 border-opacity-50 overflow-hidden'>
            <img
              className='absolute bottom-0 left-1/2 md:-mb-2 lg:-mb-20 transform -translate-x-1/2 opacity-20'
              src='aurora-assets/cta/line-green-bottom.png'
              alt=''
            />
            <img
              className='absolute bottom-0 left-1/2 -mb-5 sm:-mb-10 md:-mb-16 lg:-mb-28 transform -translate-x-1/2 opacity-20'
              src='aurora-assets/cta/light-green-bottom.png'
              alt=''
            />
            <div className='relative max-w-sm md:max-w-xl mx-auto text-center'>
              <span className='inline-flex items-center h-6 mb-4 px-2 text-sm font-medium text-white bg-gray-800 rounded-full'>
                Get Started
              </span>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-10'>
                Verify your email
              </h1>
              <button
                onClick={verify}
                className='group relative flex xs:inline-flex items-center justify-center px-5 h-12 font-bold text-sm text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300'
              >
                <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                <span className='mr-2'>Start your 14-day trial</span>
                <span className='transform group-hover:translate-x-1 transition duration-200'>
                  <svg
                    width={17}
                    height={16}
                    viewBox='0 0 17 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4.5 8H12.8333M12.8333 8L8.83334 4M12.8333 8L8.83334 12'
                      stroke='#041109'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
