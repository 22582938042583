import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className='bg-white text-black p-8'>
      <h1 className='text-2xl font-bold mb-4'>Choony.io Privacy Policy</h1>

      <section className='mb-4'>
        <h2 className='text-xl font-semibold mb-2'>1. Introduction</h2>
        <p>
          Choony.io is committed to protecting your privacy. This Privacy Policy
          outlines how we collect, use, and safeguard your information.
        </p>
      </section>

      <section className='mb-4'>
        <h2 className='text-xl font-semibold mb-2'>
          2. Information We Collect
        </h2>
        <p>We may collect the following information:</p>
        <ul className='list-disc list-inside ml-4'>
          <li>
            Personal identification information (Name, email address, phone
            number, etc.)
          </li>
          <li>Usage data (logins, interactions, preferences)</li>
          <li>Cookies and tracking technologies</li>
        </ul>
      </section>

      <section className='mb-4'>
        <h2 className='text-xl font-semibold mb-2'>
          3. How We Use Your Information
        </h2>
        <p>We use the collected information to:</p>
        <ul className='list-disc list-inside ml-4'>
          <li>Provide and maintain our services.</li>
          <li>Improve and personalize your experience.</li>
          <li>Communicate with you about updates and offers.</li>
          <li>Ensure the security and integrity of our platform.</li>
        </ul>
      </section>

      <section className='mb-4'>
        <h2 className='text-xl font-semibold mb-2'>
          4. Sharing Your Information
        </h2>
        <p>
          We do not sell, trade, or otherwise transfer your personal information
          to outside parties, except when required by law or necessary to
          protect our rights.
        </p>
      </section>

      <section className='mb-4'>
        <h2 className='text-xl font-semibold mb-2'>5. Data Security</h2>
        <p>
          We implement appropriate security measures to protect your personal
          information against unauthorized access, alteration, or destruction.
        </p>
      </section>

      <section className='mb-4'>
        <h2 className='text-xl font-semibold mb-2'>6. Data Retention</h2>
        <p>
          We retain your personal information only for as long as necessary to
          fulfill the purposes for which it was collected and to comply with
          legal obligations.
        </p>
      </section>

      <section className='mb-4'>
        <h2 className='text-xl font-semibold mb-2'>7. Your Rights</h2>
        <p>You have the right to:</p>
        <ul className='list-disc list-inside ml-4'>
          <li>Access the personal information we hold about you.</li>
          <li>Request the correction of inaccurate information.</li>
          <li>Request the deletion of your personal information.</li>
        </ul>
      </section>

      <section className='mb-4'>
        <h2 className='text-xl font-semibold mb-2'>
          8. Changes to This Privacy Policy
        </h2>
        <p>
          Choony.io reserves the right to update this Privacy Policy at any
          time. We will notify you of any changes by posting the new Privacy
          Policy on our website.
        </p>
      </section>

      <section className='mb-4'>
        <h2 className='text-xl font-semibold mb-2'>9. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at{' '}
          <a href='mailto:support@choony.io' className='text-blue-500'>
            support@choony.io
          </a>
          .
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
