import React, { useState, useEffect } from 'react';
import Toggle from './Toggle';
import { useChatbotStore } from '../store';
import { useUser } from '../providers/UserProvider';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import EmailSupportForm from './SupportForm';

//CONTACT_URL=`${process.env.REACT_APP_URL}/api/settings/${current_assistant}/contact`;
axios.defaults.withCredentials = true;
const Help = () => {
  const { id } = useParams();
  const isSubscribed = useChatbotStore((state) => state.isSubscribed);
  const subjects = [
    'General Inquiry',
    'Technical Support',
    'Automation Support',
    'Billing Issue',
    'Feedback',
    'Other',
  ];

  return (
    <>
      <section className='py-12 bg-gray-900 min-h-screen'>
        <EmailSupportForm
          title='Help / Support Form'
          subjects={subjects}
          prefix='SUPPORT-'
        />
      </section>
    </>
  );
};

export default Help;
