import React from 'react';
import CalendlyEmbed from './Calendly';

const Bookings = () => {
  return (
    <div className='bg-gray-800 min-h-screen'>
      <div className='container mx-auto px-4 py-12'>
        <h1 className='text-2xl font-bold text-white  mb-4'>Book a demo</h1>
        <CalendlyEmbed />
      </div>
    </div>
  );
};

export default Bookings;
