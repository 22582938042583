import React, { useState, useEffect } from 'react';
import { useUser } from '../providers/UserProvider';
import axios from 'axios';
import { useChatbotStore } from '../store';
axios.defaults.withCredentials = true;
const ChatbotSettings = () => {
  const {
    result: { userId },
  } = useUser();
  const assistant_id = useChatbotStore((state) => state.current_assistant);
  const [backgroundColor, setBackgroundColor] = useState('#000000');
  const [headercolor, setHeaderColor] = useState('#000000');
  const [S3LogoUrl, setS3LogoUrl] = useState('');
  const [S3LauncherIcon, setS3LauncherIcon] = useState('');
  const [error, setError] = useState(null);
  const UPLOAD_URL = `${process.env.REACT_APP_URL}/api/settings/${userId}/upload`;
  const SAVE_URL = `${process.env.REACT_APP_URL}/api/settings/${assistant_id}/save`;
  const LOAD_URL = `${process.env.REACT_APP_URL}/api/settings/${assistant_id}/load`;

  useEffect(() => {
    const loadSettings = async () => {
      try {
        setError(null);
        const authToken = localStorage.getItem('psg_auth_token');
        const response = await axios.get(LOAD_URL, {
          headers: {
            Cookie: `psg_auth_token=${authToken}`,
          },
          withCredentials: true,
        });
        setBackgroundColor(response.data.backgroundColor);
        setHeaderColor(response.data.headerColor);
        setS3LogoUrl(
          response.data.s3LogoUrl ||
            `${process.env.REACT_APP_AWS_DOMAIN}/choony.svg`
        );
        setS3LauncherIcon(
          response.data.s3LauncherIcon ||
            `${process.env.REACT_APP_AWS_DOMAIN}/choony.svg`
        );
      } catch (error) {
        console.error('Error loading settings', error);
        setBackgroundColor('#000000');
        setHeaderColor('#000000');
        setS3LogoUrl(`${process.env.REACT_APP_AWS_DOMAIN}/choony.svg`);
        setS3LauncherIcon(`${process.env.REACT_APP_AWS_DOMAIN}/choony.svg`);
        setError('Error loading settings');
      }
    };
    loadSettings();
  }, [assistant_id]);

  const saveSettings = async (e) => {
    e.preventDefault();
    setError(null);
    const remove = document.getElementById('chat-widget');
    if (remove) remove.remove();
    try {
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.post(
        SAVE_URL,
        {
          backgroundColor,
          headerColor: headercolor,
          s3LogoUrl: S3LogoUrl,
          s3LauncherIcon: S3LauncherIcon,
        },
        {
          headers: {
            Cookie: `psg_auth_token=${authToken}`,
          },
          withCredentials: true,
        }
      );
      location.reload();
    } catch (error) {
      console.error('Error saving settings', error);
      setError('Error saving settings');
    }
  };

  const uploadFile = async (e, setterFunc) => {
    try {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.post(UPLOAD_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Cookie: `psg_auth_token=${authToken}`,
        },
        withCredentials: true,
      });
      e.target.value = null;
      setterFunc(response.data.url);
    } catch (error) {
      console.error('Error uploading logo', error);
      setError('Error uploading logo');
    }
  };

  return (
    <>
      <h1 className='text-2xl font-bold text-white  mb-4'>Chatbot Settings</h1>
      <div className='mb-4'>
        <form className='w-64 '>
          <div className='mb-4'>
            <label
              className='block mb-1 text-sm font-medium text-white'
              htmlFor=''
            >
              Header Color
            </label>
            <div className='flex items-center justify-center w-full'>
              <div
                className='w-10 h-10 mr-4'
                style={{ backgroundColor: headercolor }}
              ></div>
              <input
                className='py-2 px-4 h-11 w-full text-white placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                type='text'
                value={headercolor}
                onChange={(e) => setHeaderColor(e.target.value)}
                placeholder='Header Color'
              />
            </div>
          </div>
          <div className='mb-4'>
            <label
              className='block mb-1 text-sm font-medium text-white'
              htmlFor=''
            >
              Background Color
            </label>
            <div className='flex items-center justify-center w-full'>
              <div className='w-10 h-10 mr-4' style={{ backgroundColor }}></div>

              <input
                className='py-2 px-4 h-11 w-full text-white placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                type='text'
                value={backgroundColor}
                onChange={(e) => setBackgroundColor(e.target.value)}
                placeholder='Foreground Color'
              />
            </div>
          </div>
          <div className='mb-4'>
            <label
              className='block mb-1 text-sm font-medium text-white'
              htmlFor=''
            >
              Logo (png and svg only)
            </label>
            <div className='flex items-center justify-center w-full'>
              <img src={S3LogoUrl || ''} alt='logo' className='w-10 h-10' />
              <input
                className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                type='file'
                onChange={(e) => uploadFile(e, setS3LogoUrl)}
                placeholder='S3 Logo Url'
              />
            </div>
          </div>
          <div className='mb-4'>
            <label
              className='block mb-1 text-sm font-medium text-white'
              htmlFor=''
            >
              Launcher Icon (png and svg only v)
            </label>
            <div className='flex items-center justify-center w-full'>
              <img
                src={S3LauncherIcon || ''}
                alt='launcher icon'
                className='w-10 h-10'
              />
              <input
                className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                type='file'
                onChange={(e) => uploadFile(e, setS3LauncherIcon)}
                placeholder='S3 Launcher Icon'
              />
            </div>
          </div>
          <p className='text-red-500 text-xs italic'>{error}</p>
          <div className='flex items-center justify-between'>
            <button
              className='bg-yellowGreen-500 hover:bg-yellowGreen-700 text-white font-bold py-2 px-4 rounded-md'
              type='button'
              onClick={saveSettings}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChatbotSettings;
