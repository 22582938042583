import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const useChatbotStore = create(
  persist(
    (set, get) => ({
      user_id: '',
      isSubscribed: false,
      plan: '',
      current_assistant_name: '',
      current_assistant: '',
      current_thread: '',
      current_run: '',
      addUserId: (a) => set({ user_id: a }),
      setSubscribed: (a) => set({ isSubscribed: a }),
      setPlan: (a) => set({ plan: a }),
      addAssistant: (a, b) =>
        set({ current_assistant: a, current_assistant_name: b }),
      addThread: (a) => set({ current_thread: a }),
      addRun: (a) => set({ current_run: a }),
    }),
    {
      name: 'chat-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
