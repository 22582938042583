import React, { useEffect } from 'react';

const CalendlyEmbed = () => {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    head.appendChild(script);
  }, []);
  return (
    <div className='bg-gray-800'>
      <div
        className='calendly-inline-widget'
        data-url='https://calendly.com/tommyadeniyi/30min?text_color=1a1a1a&background_color=ffffff'
        style={{ minWidth: '320px', height: '700px' }}
      ></div>
    </div>
  );
};

export default CalendlyEmbed;
