import React, { useEffect } from 'react';
import { useChatbotStore } from '../store';
import { useUser } from '../providers/UserProvider';
import { useOpenAi } from '../providers/OpenAiProvider';

const Url = ({ url = null }) => {
  const {
    allowedSites,
    updateAllowedSites,
    removeAllowedSite,
    loadAllowedSites,
    saveAllowedSites,
  } = useOpenAi();
  const current_assistant = useChatbotStore((state) => state.current_assistant);
  const [newUrl, setNewUrl] = React.useState('');
  const {
    result: { userId },
  } = useUser();

  const urlRegex =
    /^(?:(?:https?:)?\/\/)?(?:www\.)?(localhost|[a-zA-Z0-9-]+\.[a-zA-Z]{2,}|([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(?::\d+)?(?:\/[^\s]*)?$/;

  const isUrl = (urlString) => urlRegex.test(urlString);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!isUrl(newUrl)) {
      alert('Not a valid url');
      return;
    }

    updateAllowedSites(newUrl, current_assistant);
    ///await saveAllowedSites(current_assistant);

    setNewUrl('');
  };

  return (
    <>
      <div className='w-md'>
        <form className='flex items-center justify-between' action=''>
          {url ? (
            <p className='bg-gray-900 text-white p-2 rounded-lg mr-2 my-2'>
              {url}
            </p>
          ) : (
            <input
              type='text'
              className='py-2 px-4 h-11 w-full text-white placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
              placeholder='localhost:3000'
              value={newUrl}
              onChange={(e) => {
                setNewUrl(e.target.value);
              }}
            />
          )}
          {url ? (
            <button
              className='bg-gray-900 text-white p-2 rounded-lg my-2'
              onClick={async () => {
                removeAllowedSite(url, current_assistant);
                ///await saveAllowedSites(current_assistant);
              }}
            >
              Delete
            </button>
          ) : (
            <button
              className='bg-gray-900  text-white p-2 rounded-lg my-2'
              onClick={onSubmit}
            >
              Save
            </button>
          )}
        </form>
      </div>
    </>
  );
};

const AllowedUrls = () => {
  const current_assistant = useChatbotStore((state) => state.current_assistant);
  const {
    result: { userId },
  } = useUser();
  const { allowedSites, loadAllowedSites, saveAllowedSites } = useOpenAi();
  useEffect(() => {
    if (!current_assistant || userId === undefined) return;
    const load = async () => await loadAllowedSites(current_assistant);
    load();
  }, [current_assistant, userId]);

  return (
    <>
      <p className='text-2xl text-white font-bold mb-2'>
        Add Url domain or subdomain without http(s)// or www e.g example.com{' '}
      </p>
      <Url url={null} />
      <p className='text-2xl text-white font-bold'>Allowed Urls for Chatbot</p>
      {Array.from(allowedSites).map((url) => {
        return <Url url={url} key={url} />;
      })}
    </>
  );
};

export default AllowedUrls;
