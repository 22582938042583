import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Cookie() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <div className='fixed z-50 top-0 w-full py-8'>
        <div className='max-w-6xl px-4 xl:px-0 mx-auto'>
          <div className='p-6 bg-gray-900 rounded-xl border-2 border-teal-700 shadow-3xl'>
            <div className='flex flex-wrap items-center -mx-4'>
              <div className='w-full md:w-8/12 px-4 mb-8 lg:mb-0'>
                <div className='max-w-xl'>
                  <p className='text-gray-200 mb-4'>
                    We use technologies such as cookies, to process information
                    about you and your devices to operate, analyze, and improve
                    this site.
                  </p>
                  <a
                    className='inline-block text-sm text-white hover:text-yellowGreen-600 font-semibold'
                    href='#'
                  >
                    Cookie Policy
                  </a>
                </div>
              </div>
              <div className='w-full md:w-4/12 px-4 text-right'>
                <a
                  className='group inline-block justify-center p-1 text-center text-base text-white font-semibold rounded-lg'
                  href='#'
                >
                  <div className='inline-flex items-stretch h-12 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                    <div className='flex items-center px-5 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                      <span className='-mb-px'>Good to know</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

