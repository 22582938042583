import React from 'react';

const Toggle = ({ options, selected, onChange, name }) => {
  return (
    <div className='text-white flex items-center'>
      {name}
      <div className='inline-flex items-center h-6 px-px mx-4 bg-yellowGreen-600 rounded-full'>
        {options.map((option, index) => (
          <button
            key={index}
            onClick={() => onChange(option)}
            className={`inline-block w-5 h-5 rounded-full ${
              selected === option ? 'bg-white' : 'bg-transparent'
            } focus:outline-none`}
          />
        ))}
      </div>
    </div>
  );
};

export default Toggle;
