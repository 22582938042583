import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className='bg-white text-black p-8'>
      <h1 className='text-2xl font-bold mb-4'>
        Choony.io Terms and Conditions
      </h1>

      <section className='mb-4'>
        <h2 className='text-xl font-semibold mb-2'>1. Introduction</h2>
        <p>
          Welcome to Choony.io, a SaaS platform providing AI chatbot services.
          By using our services, you agree to comply with and be bound by the
          following terms and conditions.
        </p>
      </section>

      <section className='mb-4'>
        <h2 className='text-xl font-semibold mb-2'>2. Eligibility</h2>
        <p>
          Choony.io services are intended for small businesses and individual
          websites. If your requirements exceed these specifications, please
          consider our enterprise plan for additional resources and custom
          integrations.
        </p>
      </section>

      <section className='mb-4'>
        <h2 className='text-xl font-semibold mb-2'>3. Account Usage</h2>
        <p>
          You are responsible for maintaining the confidentiality of your
          account and password. You agree to notify us immediately of any
          unauthorized use of your account.
        </p>
      </section>

      <section className='mb-4'>
        <h2 className='text-xl font-semibold mb-2'>4. Prohibited Activities</h2>
        <p>
          Users must not abuse the platform. Prohibited activities include, but
          are not limited to:
        </p>
        <ul className='list-disc list-inside ml-4'>
          <li>Spamming or sending unsolicited messages.</li>
          <li>Using the platform for any illegal activities.</li>
          <li>
            Attempting to disrupt or interfere with the platform’s operations.
          </li>
        </ul>
      </section>

      <section className='mb-4'>
        <h2 className='text-xl font-semibold mb-2'>5. Termination</h2>
        <p>
          Choony.io reserves the right to terminate any account that violates
          these terms or engages in abusive activities without prior notice.
        </p>
      </section>

      <section className='mb-4'>
        <h2 className='text-xl font-semibold mb-2'>
          6. Limitation of Liability
        </h2>
        <p>
          Choony.io shall not be liable for any indirect, incidental, or
          consequential damages arising out of the use of the platform.
        </p>
      </section>

      <section className='mb-4'>
        <h2 className='text-xl font-semibold mb-2'>7. Changes to Terms</h2>
        <p>
          Choony.io reserves the right to modify these terms at any time. Your
          continued use of the platform constitutes acceptance of any changes.
        </p>
      </section>

      <section className='mb-4'>
        <h2 className='text-xl font-semibold mb-2'>8. Contact Information</h2>
        <p>
          For any questions about these Terms and Conditions, please contact us
          at{' '}
          <a href='mailto:support@choony.io' className='text-blue-500'>
            support@choony.io
          </a>
          .
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
