import React from 'react';

const InfoBox = ({ title, description, message, maxWidth = 'sm' }) => {
  return (
    <div className={`flex flex-col h-full max-w-${maxWidth}`}>
      <h1 className='font-heading tracking-tight text-4xl xs:text-5xl font-bold text-white mb-4'>
        {title}
      </h1>
      <p className='max-w-xs text-lg text-gray-400 mb-10'>{description}</p>
      <div className='mt-auto p-8 border border-gray-700 rounded-xl'>
        <img
          className='block mb-8'
          src='aurora-assets/logos/logo-aurora-white.svg'
          alt=''
        />
        <p className='font-medium text-white'>{message}</p>
      </div>
    </div>
  );
};

export default InfoBox;
