import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useOpenAi } from '../providers/OpenAiProvider';
axios.defaults.withCredentials = true;
const ApiKeyManager = ({ userId }) => {
  const [apiKey, setApiKey] = useState('');
  const [obfuscatedKey, setObfuscatedKey] = useState(null);
  const { getModels } = useOpenAi();

  // URL for the API endpoint (modify as necessary)
  const apiUrl = `${process.env.REACT_APP_URL}/api/user/${userId}/key`;

  // Function to read the API key with retry mechanism
  const readApiKey = async (attempt = 0) => {
    const authToken = localStorage.getItem('psg_auth_token');
    try {
      const response = await axios.get(apiUrl, {
        headers: { Cookie: `psg_auth_token=${authToken}` },
      });
      setObfuscatedKey(response.data.openai_key);
      getModels(userId);
    } catch (error) {
      if (attempt < 2) {
        // Retry up to 3 times (0, 1, 2)
        console.log(`Retry attempt ${attempt + 1}`);
        setTimeout(() => readApiKey(attempt + 1), 1000); // Wait 1 second before retrying
      } else {
        console.error('Error fetching API key after 3 attempts', error);
      }
    }
  };
  // Function to create or update the API key
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!apiKey) return; // Don't submit if the API key is empty
    const method = obfuscatedKey ? 'patch' : 'post'; // Use PATCH if an API key exists, otherwise use POST

    try {
      const response = await axios({
        method,
        url: apiUrl,
        data: { apiKey },
      });
      setObfuscatedKey(response.data.openai_key);
      getModels(userId);
      setApiKey(''); // Clear input after submission
    } catch (error) {
      console.error('Error updating API key', error);
    }
  };

  // Function to delete the API key
  const deleteApiKey = async () => {
    try {
      await axios.delete(apiUrl);
      setObfuscatedKey(null);
    } catch (error) {
      console.error('Error deleting API key', error);
    }
  };

  useEffect(() => {
    readApiKey(); // Initial call without delay
  }, [userId]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className='xs:flex items-center'>
          <input
            className='py-3 px-4 h-12 w-full mb-3 xs:mb-0 xs:mr-3 text-gray-400 placeholder-gray-400 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-800'
            type='password'
            name='openai_api_key'
            placeholder='OPEN API KEY'
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            autocomplete='new-password'
          />
          <button
            className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center px-5 h-12 font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
            type='submit'
          >
            <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
            <span>Submit</span>
          </button>
        </div>
      </form>
      <p className='text-left text-white flex'>
        Saved Key:{' '}
        {obfuscatedKey &&
        !obfuscatedKey
          .toLocaleLowerCase()
          .includes('laceMe'.toLocaleLowerCase())
          ? 'Yes'
          : 'No'}
      </p>
      {/* <button onClick={deleteApiKey}>Delete API Key</button> */}
    </div>
  );
};

export default ApiKeyManager;
