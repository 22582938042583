import React, { useState, useEffect, useRef } from 'react';
import { useOpenAi } from '../providers/OpenAiProvider';
import axios from 'axios';
import { useUser } from '../providers/UserProvider';
import Auth from './Auth';
axios.defaults.withCredentials = true;
const EditChatbotForm = ({ assistant }) => {
  const MAXINSTRUCTIONCHARS = 32768;
  const [chatbot, setChatbot] = useState(null);
  const [instructuctionChars, setInstructionChars] = useState(0);
  const { model, instructions } = chatbot || {};

  const chatbot_name = assistant.name;
  const current_assistant = assistant.assistant_id;
  const {
    result: { userId },
  } = useUser();
  const { models } = useOpenAi();
  const [originalData, setOriginalData] = useState(null);
  const focusRef = useRef(null);
  const [message, setMessage] = useState(null);

  const resourcePath = `${process.env.REACT_APP_URL}/api/openai/assistant/${userId}/${current_assistant}`;

  useEffect(() => {
    if (!focusRef.current) return;
    setTimeout(() => {
      focusRef.current?.focus();
    }, 1000);
  }, [focusRef]);

  useEffect(() => {
    (async () => {
      try {
        const authToken = localStorage.getItem('psg_auth_token');
        const response = await axios.get(resourcePath, {
          headers: {
            Cookie: `psg_auth_token=${authToken}`,
          },
          withCredentials: true,
        });
        setOriginalData(response.data); // Save the original data
        setChatbot(response.data); // Set the chatbot data
        focusRef.current?.focus(); // Optionally set focus when data is loaded
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setMessage('Failed to load chatbot data.');
      }
    })();
  }, [resourcePath]);

  const onChangeChatbot = (changes) => {
    setMessage(null);
    setChatbot({ ...chatbot, ...changes });
  };

  const onSaveChatbot = async () => {
    setMessage(null);
    try {
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.post(
        resourcePath,
        { chatbot },
        {
          headers: {
            Cookie: `psg_auth_token=${authToken}`,
          },
          withCredentials: true,
        }
      );
      setOriginalData(response.data); // Update the original data
      setChatbot(response.data); // Update the chatbot data
      setMessage('Chatbot saved');
      focusRef.current?.focus(); // Optionally set focus after saving
    } catch (error) {
      console.error('Failed to save data:', error);
      setMessage('Failed to save chatbot data.');
    }
  };

  const onResetChatbot = () => {
    setChatbot(originalData); // Reset chatbot data to original data
    setMessage(null);
  };
  return (
    <section tabIndex={0}>
      <div className='mb-4'>
        <label className='block mb-1 text-sm font-medium text-white' htmlFor=''>
          Chatbot Name
        </label>
        <input
          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
          type='text'
          value={chatbot_name}
          readOnly
          placeholder='Chatbot name'
          tabIndex={0}
        />
      </div>
      <div className='mb-4'>
        <label className='block mb-1 text-sm font-medium text-white' htmlFor=''>
          Model
        </label>
        <select
          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
          type='select'
          value={model}
          readOnly
          tabIndex={0}
        >
          <option value={model}>{model}</option>
        </select>
      </div>
      <div className='mb-8'>
        <label className='block mb-1 text-sm font-medium text-white' htmlFor=''>
          Edit or Update Instructions
        </label>
        <textarea
          className='block py-2 px-4 w-full mb-1 h-44 text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500 resize-none'
          placeholder='Enter your instructions'
          value={instructions}
          tabIndex={0}
          ref={focusRef}
          onChange={(e) => {
            onChangeChatbot({ ...chatbot, instructions: e.target.value });
            setInstructionChars(e.target.value.length);
          }}
        />
        {instructuctionChars <= MAXINSTRUCTIONCHARS ? (
          <label
            className='block mt-1 text-sm font-medium text-white'
            htmlFor=''
          >
            You have up to {MAXINSTRUCTIONCHARS} characters to use. You have
            used {instructions ? instructions.length : 0} characters.
          </label>
        ) : (
          <label
            className='block mt-1 text-sm font-medium text-white'
            htmlFor=''
          >
            You are over the character limit. You have used{' '}
            {instructuctionChars} characters.
          </label>
        )}
      </div>
      <p className='text-white my-2'>{message}</p>
      <div className='mb-4 flex gap-2'>
        <button
          className='xs:flex-shrink-0 group relative flex-1 w-1/2 h-14 xs:inline-flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
          onClick={onResetChatbot}
        >
          <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
          <span>Reset</span>
        </button>
        <button
          className='xs:flex-shrink-0 group relative flex-1 w-1/2 h-14 xs:inline-flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
          onClick={onSaveChatbot}
        >
          <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
          <span>Submit</span>
        </button>
      </div>
    </section>
  );
};

export default EditChatbotForm;
