import React, { useState } from 'react';
import { useUser } from '../providers/UserProvider';
import { useChatbotStore } from '../store';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    result: { isAuthorized },
    signOut,
  } = useUser();
  const isSubscribed = useChatbotStore((state) => state.isSubscribed);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <section>
      <nav className='bg-gray-900'>
        <div className='container mx-auto px-4'>
          <div className='relative flex h-24 items-center'>
            <a className='inline-block' href='/'>
              <span className='flex justify-center items-center text-bold text-2xl text-white'>
                <img
                  className='h-14'
                  src='/aurora-assets/logos/choony.svg'
                  alt='Logo'
                />
                Choony.io
              </span>
            </a>
            <button
              onClick={toggleMenu}
              className='lg:hidden navbar-burger flex items-center justify-center h-10 w-10 ml-auto border border-teal-900 bg-teal-200 bg-opacity-30 hover:bg-teal-700 rounded-lg'
            >
              <svg
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M3 5H21'
                  stroke='white'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M3 12H21'
                  stroke='white'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M3 19H21'
                  stroke='white'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </button>
            <div
              className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                isMenuOpen ? 'hidden' : 'hidden lg:flex'
              } lg:flex items-center`}
            >
              {isAuthorized ? (
                <>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='/dashboard'
                  >
                    <div className='flex items-center'>
                      <span className='mr-3'>Dashboard</span>
                      <svg
                        width={10}
                        height={6}
                        viewBox='0 0 10 6'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1 1L5 5L9 1'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='/profile'
                  >
                    Profile
                  </a>
                  {isSubscribed ? (
                    <>
                      <a
                        className='inline-block text-white hover:text-teal-400 mr-10'
                        href='/account'
                      >
                        Account
                      </a>
                      <a
                        className='inline-block text-white hover:text-teal-400 mr-10'
                        href='/help'
                      >
                        Help
                      </a>
                    </>
                  ) : (
                    <a
                      className='inline-block text-white hover:text-teal-400 mr-10'
                      href='/checkout'
                    >
                      Subscribe
                    </a>
                  )}
                </>
              ) : (
                <>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='/pricing'
                  >
                    Pricing
                  </a>
                </>
              )}
              <a
                className='inline-block text-white hover:text-teal-400 mr-10'
                href='/demo'
              >
                Book a Demo
              </a>
            </div>
            <div className='hidden lg:block ml-auto'>
              {isAuthorized ? (
                <button
                  className='inline-flex items-center justify-center h-10 mr-4 px-4 text-center leading-loose text-sm text-white hover:text-teal-400 font-semibold transition duration-200'
                  onClick={signOut}
                >
                  Sign Out
                </button>
              ) : (
                <a
                  className='inline-flex items-center justify-center h-10 mr-4 px-4 text-center leading-loose text-sm text-white hover:text-teal-400 font-semibold transition duration-200'
                  href='/auth'
                >
                  Login / Register
                </a>
              )}
            </div>
          </div>
        </div>
      </nav>
      <div
        className={`${
          isMenuOpen ? 'block' : 'hidden'
        } navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50`}
      >
        <div
          className='navbar-backdrop fixed inset-0 bg-teal-800 opacity-70'
          onClick={toggleMenu}
        />
        <nav className='relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto'>
          <div className='flex mb-auto items-center'>
            <a className='inline-block mr-auto' href='#'>
              <span className='flex justify-center items-center text-bold text-2xl text-black'>
                <img
                  className='h-14'
                  src='/aurora-assets/logos/choony.svg'
                  alt='Logo'
                />
                Choony.io
              </span>
            </a>
            <button onClick={toggleMenu} className='navbar-close'>
              <svg
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M6 18L18 6M6 6L18 18'
                  stroke='#111827'
                  strokeWidth={2}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </button>
          </div>
          <div className='py-12 mb-auto'>
            <ul className='flex-col'>
              {isAuthorized ? (
                <>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='/dashboard'>
                      Dashboard
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='/profile'>
                      Profile
                    </a>
                  </li>

                  {isSubscribed ? (
                    <>
                      <li className='mb-6'>
                        <a className='inline-block text-black' href='/account'>
                          Account
                        </a>
                      </li>
                      <li className='mb-6'>
                        <a className='inline-block text-black' href='/help'>
                          Help
                        </a>
                      </li>
                    </>
                  ) : (
                    <li className='mb-6'>
                      <a className='inline-block text-black' href='/checkout'>
                        Subscribe
                      </a>
                    </li>
                  )}
                </>
              ) : (
                <>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='/pricing'>
                      Pricing
                    </a>
                  </li>
                </>
              )}
              <li className='mb-6'>
                <a className='inline-block text-black' href='/demo'>
                  Book a Demo
                </a>
              </li>
            </ul>
          </div>
          <div>
            {isAuthorized ? (
              <button
                className='flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200'
                onClick={signOut}
              >
                Sign Out
              </button>
            ) : (
              <a
                className='flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200'
                href='/auth'
              >
                Login/Register
              </a>
            )}
          </div>
        </nav>
      </div>
    </section>
  );
};

export default Navbar;
