import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useUser } from '../providers/UserProvider';
import { Link } from 'react-router-dom';
import ApiKeyManager from './ApiKey';
import CreateChatbot from './CreateChatbot';
import InfoBox from './InfoBox';
import { useOpenAi } from '../providers/OpenAiProvider';
import ChatbotsTable from './ChatbotsTable';

export default function Dashboard() {
  const {
    result: { isLoading, isAuthorized, username, userId, userInfo, subscribed },
  } = useUser();
  const { models, getChatbots } = useOpenAi();
  if (isLoading) return null;
  useEffect(() => {
    getChatbots(userId);
  }, []);
  return (
    <React.Fragment>
      <>
        <section className='relative py-24 bg-gray-900'>
          <img
            className='absolute top-0 left-1/2 -mb-5 sm:-mb-10 md:-mb-16 lg:-mb-28 transform -translate-x-1/2 opacity-20'
            src='aurora-assets/cta/light-green-top.png'
            alt=''
          />
          {isAuthorized === true ? (
            <div className='relative container px-4 mx-auto'>
              <div className='relative max-w-lg mx-auto text-center'>
                {/* {!subscribed && (
                  <span className='inline-flex items-center h-6 mb-4 px-2 text-sm font-medium text-white bg-gray-800 rounded-full'>
                    14-day free trial
                  </span>
                )} */}
                {subscribed ? (
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                    How are you today?
                  </h1>
                ) : (
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                    Try Choony today
                  </h1>
                )}
                <p className='text-gray-400 mb-10'>
                  Making creating chatbots easy!
                </p>
                <ApiKeyManager userId={userId} />
              </div>
            </div>
          ) : (
            <p className='text-center text-white'>
              You must <Link to='/'>login</Link> to view this page!
            </p>
          )}
        </section>
        {isAuthorized === true && (
          <section className='py-12 md:py-24 bg-gray-900'>
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap -mx-4 mb-4'>
                <div className='w-full lg:w-5/12 xl:w-1/2 px-4 mb-16 lg:mb-0'>
                  <InfoBox
                    title={'Create a chatbot'}
                    description={'Fill in the Chatbot Info using the form'}
                    message={JSON.stringify(
                      {
                        email: userInfo.email,
                        email_verified: userInfo.email_verified,
                        phone: userInfo.phone,
                        phone_verified: userInfo.phone_verified,
                      },
                      null,
                      2
                    )}
                  />
                </div>

                <div className='w-full lg:w-7/12 xl:w-1/2 px100-4'>
                  {models.length > 0 && (
                    <div className='p-8 border border-gray-700 rounded-xl'>
                      <CreateChatbot />
                    </div>
                  )}
                </div>
              </div>
              <ChatbotsTable />
            </div>
          </section>
        )}
        <div />
      </>
    </React.Fragment>
  );
}
