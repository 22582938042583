import React, { useRef } from 'react';
import { useOpenAi } from '../providers/OpenAiProvider';
import LinkButton from './LinkButton';
import { useChatbotStore } from '../store';
import { useNavigate } from 'react-router-dom';
import { Modal } from './UncontrolledModal';
import EditChatbot from './EditChatbot';
import { useUser } from '../providers/UserProvider';
import EditChatbotForm from './EditChatbotForm';
import { ControlledModal } from './ControlledModal copy';

const ChatbotsTable = () => {
  const { chatbots, deleteChatbot, getChatbots } = useOpenAi();

  const {
    result: { userId },
  } = useUser();
  const navigate = useNavigate();
  const addAssistant = useChatbotStore((state) => state.addAssistant);
  const addUserId = useChatbotStore((state) => state.addUserId);
  const testBot = (path, id, name) => {
    addAssistant(id, name);
    navigate(path);
  };
  const onDelete = async (assistant_id) => {
    await deleteChatbot(userId, assistant_id);
    await getChatbots(userId);
  };
  return (
    <div className='overflow-x-auto'>
      <table className='w-full text-left mb-2'>
        <thead className='bg-gray-800 text-white rounded-lg'>
          <tr className='flex flex-nowrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0'>
            <th className='p-3 text-left'>Chatbot</th>
            <th className='p-3 text-left'>Assistant Id</th>
            <th className='p-3 text-left'>Instructions</th>
            <th className='p-3 text-left'>Test</th>
            <th className='p-3 text-left'>Actions</th>
          </tr>
        </thead>
        <tbody className='flex-1 sm:flex-none'>
          {chatbots?.map((assistant) => (
            <tr
              className='flex flex-nowrap sm:table-row mb-2 sm:mb-0 text-white'
              key={assistant.id}
            >
              <td className='border-grey-light border  p-3 '>
                <div className='flex items-center'>
                  <div className='flex-shrink-0 w-10 h-10'>
                    <img
                      className='w-full h-full rounded-full'
                      src={
                        assistant?.settings?.s3LauncherIcon ||
                        'https://choony.s3.amazonaws.com/choony.svg'
                      }
                      alt=''
                    />
                  </div>
                  <div className='ml-3'>
                    <p className=' whitespace-no-wrap'>{assistant.name}</p>
                  </div>
                </div>
              </td>
              <td className='border-grey-light border  p-3'>
                <p className=' whitespace-no-wrap'>{assistant.assistant_id}</p>
              </td>
              <td className='border-grey-light border  p-3 hover:bg-gray-100'>
                <Modal title={'Manage Training'}>
                  <EditChatbotForm assistant={assistant} />
                  <EditChatbot assistant={assistant} />
                </Modal>
                <LinkButton
                  action={() =>
                    testBot(
                      `/automations/${assistant.id}`,
                      assistant.assistant_id,
                      assistant.name
                    )
                  }
                  text={'Manage Automations'}
                />
              </td>
              <td className='border-grey-light border hover:bg-gray-100 p-3'>
                <LinkButton
                  action={() =>
                    testBot(
                      `/chatbot/${assistant.id}`,
                      assistant.assistant_id,
                      assistant.name
                    )
                  }
                  text={'Test'}
                />
              </td>
              <td className='border-grey-light border hover:bg-gray-100 p-3'>
                <button
                  onClick={() => onDelete(assistant.assistant_id)}
                  className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-3 rounded-full'
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ChatbotsTable;
