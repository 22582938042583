import React, { useEffect, createContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useChatbotStore } from '../store';
import { useUser } from './UserProvider';

const REACT_APP_HELP_USER_ID = process.env.REACT_APP_HELP_USER_ID;
const REACT_APP_HELP_ASSISTANT_ID = process.env.REACT_APP_HELP_ASSISTANT_ID;
const REACT_APP_URL = process.env.REACT_APP_URL;

export const HelpChatbotContext = createContext();

const HelpChatbotProvider = ({ children }) => {
  const { current_assistant } = useChatbotStore((state) => state);
  const {
    result: { userId },
  } = useUser();
  const location = useLocation();

  const removeChatbot = () => {
    const scriptElement = document.getElementById('chat-widget-loader');
    scriptElement?.remove();
    const chatIcons = document.getElementsByClassName('chat-icon-instance');
    Array.from(chatIcons)?.forEach((icon) => icon.remove());
  };

  const loadScript = (src) => {
    removeChatbot(); // Ensure any existing script is removed
    const script = document.createElement('script');
    script.id = 'chat-widget-loader';
    script.src = src;
    script.async = true;
    script.onload = () => {
      // Ensure widget initialization function exists before calling
      if (typeof window.chatWidgetInit === 'function') {
        window.chatWidgetInit();
      }
    };
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (location.pathname === '/auth') return;

    const scriptBaseURL = `${REACT_APP_URL}/chat`;
    if (location.pathname.includes('/chatbot/')) {
      userId &&
        current_assistant &&
        loadScript(
          `${scriptBaseURL}/${userId}/loader/${current_assistant}.js?time=${Date.now()}`
        );
    } else if (REACT_APP_HELP_USER_ID && REACT_APP_HELP_ASSISTANT_ID) {
      loadScript(
        `${scriptBaseURL}/${REACT_APP_HELP_USER_ID}/loader/${REACT_APP_HELP_ASSISTANT_ID}.js?time=${Date.now()}`
      );
    }

    return removeChatbot;
  }, [location.pathname, current_assistant, userId]);

  return (
    <HelpChatbotContext.Provider value={{}}>
      {children}
    </HelpChatbotContext.Provider>
  );
};

export default HelpChatbotProvider;
