// OpenAiContext.js

import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';

/**app.add_url_rule('/api/openai/assistants/<user_id>/<assistant_id>/files', view_func=list_files, methods=['GET'])
app.add_url_rule('/api/openai/assistants/<user_id>/<assistant_id>/files', view_func=create_assistant_file, methods=['POST'])
app.add_url_rule('/api/openai/assistants/<user_id>/<assistant_id>/files/upload', view_func=upload_assistant_file, methods=['POST'])
app.add_url_rule('/api/openai/assistants/<user_id>/<assistant_id>/files/<file_id>', view_func=delete_assistant_file, methods=['DELETE'])
app.add_url_rule('/api/settings/<assistant_id>/sites', view_func=load_allowed_sites, methods=['GET'])
app.add_url_rule('/api/settings/<assistant_id>/sites', view_func=save_allowed_sites, methods=['POST'])
*/
axios.defaults.withCredentials = true;
const OpenAiContext = createContext();
const AssistantModels = [
  { id: 'gpt-4-1106-preview' },
  { id: 'gpt-4o' },
  //{ id: 'gpt-3.5-turbo-16k' },
  { id: 'gpt-3.5-turbo-1106' },
  //{ id: 'gpt-3.5-turbo' },
];
const MAX_CHATBOTS = 5;
export const OpenAiProvider = ({ children }) => {
  const [models, setModels] = useState([]);
  const [chatbots, setChatbots] = useState([]);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState([]);
  const [allowedSites, setAllowedSites] = useState(new Set());
  const modelUrl = `${process.env.REACT_APP_URL}/api/openai/models/`;
  const chatbotUrl = `${process.env.REACT_APP_URL}/api/openai/assistant/`;
  const chatbotsUrl = `${process.env.REACT_APP_URL}/api/openai/assistants/`;
  const settingsUrl = `${process.env.REACT_APP_URL}/api/settings/`;

  const getFiles = async (userId, assistantId) => {
    try {
      setError(null);
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/openai/assistants/${userId}/${assistantId}/files`,
        {
          headers: {
            Cookie: `psg_auth_token=${authToken}`,
          },
          withCredentials: true,
        }
      );
      //console.log({ files: response.data });
      setFiles(response.data);
    } catch (error) {
      console.error('Error fetching files', error);
      setError('Error fetching files');
    }
  };

  const createFile = async (userId, assistantId, data) => {
    try {
      setError(null);
      //console.log({ data, chatbotUrl, userId });
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/openai/assistants/${userId}/${assistantId}/files`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Cookie: `psg_auth_token=${authToken}`,
          },
          withCredentials: true,
        }
      );
      //console.log({ file: response.data });
    } catch (error) {
      console.error('Error creating file', error);
      setError('Error creating file');
    }
  };

  const uploadFile = async (userId, assistantId, data) => {
    console.log({ data, userId, assistantId });
    try {
      setError(null);
      //console.log({ data, chatbotUrl, userId });
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/openai/assistants/${userId}/${assistantId}/files/upload`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Cookie: `psg_auth_token=${authToken}`,
          },
          withCredentials: true,
        }
      );
      //console.log({ file: response.data });
    } catch (error) {
      console.error('Error uploading file', error);
      setError('Error uploading file');
    }
  };

  const deleteFile = async (userId, assistant_id, fileId) => {
    try {
      setError(null);
      //console.log({ chatbotUrl, userId });
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}/api/openai/assistants/${userId}/${assistant_id}/files/${fileId}`,
        {
          headers: {
            Cookie: `psg_auth_token=${authToken}`,
          },
          withCredentials: true,
        }
      );
      //console.log({ file: response.data });
    } catch (error) {
      console.error('Error deleting file', error);
      setError('Error deleting file');
    }
  };

  // Function to get the models
  const getModels = async (userId) => {
    try {
      setError(null);
      const authtoken = localStorage.getItem('psg_auth_token');
      const response = await axios.get(modelUrl + userId, {
        headers: { Cookie: `psg_auth_token=${authtoken}` },
      });
      //console.log({ models: response.data, modelUrl });
      // set models to the assistanModels in response.data
      if (response.data.length === 0) {
        setError('No models found. INVALID API KEY');
      } else {
        const allowedModels = response.data.filter((model) =>
          AssistantModels.some((am) => am.id === model.id)
        );
        setModels(allowedModels);
      }
    } catch (error) {
      console.error('Error fetching models', error);
      setError('Error fetching models');
    }
  };

  const createChatbot = async (userId, data) => {
    if (chatbots.length >= MAX_CHATBOTS) {
      setError('You have reached the maximum number of chatbots');
      return;
    }
    try {
      setError(null);
      //console.log({ data, chatbotUrl, userId });
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.post(chatbotUrl + userId, data, {
        headers: { Cookie: `psg_auth_token=${authToken}` },
      });
      //console.log({ chatbot: response.data, chatbotUrl });
    } catch (error) {
      console.error('Error creating chatbot', error);
      setError('Error creating chatbot');
    }
  };

  // Define other methods similarly
  const getChatbots = async (userId) => {
    try {
      setError(null);
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.get(chatbotsUrl + userId, {
        headers: { Cookie: `psg_auth_token=${authToken}` },
      });
      //console.log({ assistants: response.data, chatbotsUrl });
      setChatbots(response.data);
    } catch (error) {
      console.error('Error fetching assistants', error);
      setError('Error fetching assistants');
    }
  };

  const deleteChatbot = async (user_id, assistant_id) => {
    try {
      setError(null);
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.delete(
        chatbotUrl + user_id + '/' + assistant_id,
        {
          headers: { Cookie: `psg_auth_token=${authToken}` },
        }
      );
      //console.log({ assistants: response.data, chatbotsUrl });
      //setChatbots(response.data);
    } catch (error) {
      console.error('Error deleting assistant', error);
      setError('Error deleting assistant');
    }
  };

  const updateAllowedSites = (newSite, assistant_id) => {
    // Create a new Set based on the current allowedSites
    const updatedSites = new Set(allowedSites);

    // Add the new site
    updatedSites.add(newSite);

    // Update the state with the new Set

    setAllowedSites(updatedSites);

    // Save the new allowed sites
    saveAllowedSites(assistant_id, updatedSites);
  };

  const removeAllowedSite = (site, assistant_id) => {
    // Create a new Set based on the current allowedSites
    const updatedSites = new Set(allowedSites);

    // Add the new site
    updatedSites.delete(site);

    // Update the state with the new Set
    setAllowedSites(updatedSites);

    // Save the new allowed sites
    saveAllowedSites(assistant_id, updatedSites);
  };

  const loadAllowedSites = async (assistant_id) => {
    try {
      setError(null);
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.get(settingsUrl + assistant_id + '/sites', {
        headers: { Cookie: `psg_auth_token=${authToken}` },
      });
      //console.log({ allowedSites: response.data, settingsUrl });
      const allowed = new Set(response?.data?.sites.split(',') || []);
      setAllowedSites(allowed);
    } catch (error) {
      console.error('Error fetching allowed sites', error);
      setError('Error fetching allowed sites');
    }
  };

  const saveAllowedSites = async (assistant_id, sites) => {
    try {
      setError(null);
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.post(
        settingsUrl + assistant_id + '/sites',
        {
          allowed_sites: Array.from(sites).join(','),
        },
        { headers: { Cookie: `psg_auth_token=${authToken}` } }
      );
      //console.log({ allowedSites: response.data, settingsUrl });
    } catch (error) {
      console.error('Error saving allowed sites', error);
      setError('Error saving allowed sites');
    }
  };

  return (
    <OpenAiContext.Provider
      value={{
        error,
        models,
        getModels,
        chatbots,
        getChatbots,
        createChatbot,
        getFiles,
        files,
        //createFile,
        uploadFile,
        deleteFile,
        deleteChatbot,
        allowedSites,
        updateAllowedSites,
        removeAllowedSite,
        loadAllowedSites,
        saveAllowedSites,
      }}
    >
      {children}
    </OpenAiContext.Provider>
  );
};

export const useOpenAi = () => {
  const context = useContext(OpenAiContext);
  if (!context) {
    throw new Error(
      'You are trying to use context data outside of its provider'
    );
  }
  return context;
};
