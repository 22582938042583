import React, { useEffect } from 'react';
import { useOpenAi } from '../providers/OpenAiProvider';
import { useUser } from '../providers/UserProvider';
import { useChatbotStore } from '../store';
import LinkButton from './LinkButton';

const File = ({ assistant_id = null, file = null }) => {
  const { getFiles, uploadFile, deleteFile } = useOpenAi();
  const {
    result: { userId },
  } = useUser();
  const uploadAssistantFile = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    await uploadFile(userId, assistant_id, formData);
    await getFiles(userId, assistant_id);
    e.target.value = null;
  };
  const onDeleteFile = async (fileId) => {
    await deleteFile(userId, assistant_id, fileId);
    await getFiles(userId, assistant_id);
  };
  return (
    <>
      <div className='flex items-center justify-between mb-2'>
        {file ? (
          <>
            <span className='bg-gray-900 flex-1 text-white p-2 rounded-lg mr-2 my-2'>
              {file.filename}
            </span>
            <LinkButton
              action={() => onDeleteFile(file.file_id)}
              text={'Delete'}
            />
          </>
        ) : (
          <input
            className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
            type='file'
            onChange={uploadAssistantFile}
          />
        )}
      </div>
    </>
  );
};

const EditChatbot = ({ assistant }) => {
  const { files, getFiles, uploadFile, deleteFile } = useOpenAi();
  const {
    result: { userId },
  } = useUser();
  useEffect(() => {
    getFiles(userId, assistant.assistant_id);
  }, []);
  return (
    <div>
      <p className='text-white text-lg font-bold'>Assistant Files</p>
      <File assistant_id={assistant.assistant_id} />
      {files.map((file) => {
        return (
          <File
            file={file}
            key={file.id}
            assistant_id={assistant.assistant_id}
          />
        );
      })}
    </div>
  );
};

export default EditChatbot;
