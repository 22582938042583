import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '@passageidentity/passage-elements/passage-profile';
const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Profile() {
  const app_id = process.env.REACT_APP_PASSAGE_APP_ID;

  console.log({ APP_ID: app_id ? 'APP_ID_LOADED!!' : 'APP_ID_NOT_LOADED' });

  // define back function using react-router-dom
  const goBack = () => {
    window.history.back();
  };

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section className='bg-gray-900 min-h-screen'>
        <div className='flex flex-wrap'>
          <div className='w-full lg:w-5/12 px-5'>
            <div className='py-12 md:pt-8 h-full'>
              <div className='flex flex-col h-full max-w-xs mx-auto'>
                <img
                  className='block w-64 mb-10'
                  src='aurora-assets/sign-up/circle-image-dark.png'
                  alt=''
                />
                <h3
                  className='font-heading tracking-tight text-4xl font-bold text-white mb-4'
                  contentEditable='false'
                >
                  Profile
                </h3>
                <button
                  onClick={goBack}
                  className='inline-flex mt-auto items-center text-sm text-white hover:text-yellowGreen-600 font-semibold'
                >
                  <svg
                    width={20}
                    height={20}
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M12.5 5L7.5 10L12.5 15'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <span className='ml-2'>Back</span>
                </button>
              </div>
            </div>
          </div>
          <div className='w-full lg:w-7/12 px-5'>
            <div className='py-12 md:pt-8 md:px-8'>
              <div className='max-w-sm lg:max-w-md xl:max-w-lg mx-auto lg:mr-0 xl:mx-auto'>
                <div className='max-w-sm'>
                  {' '}
                  <passage-profile
                    app-id={app_id}
                    theme='dark'
                  ></passage-profile>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
