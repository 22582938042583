import React, { useState, useEffect } from 'react';
import Toggle from './Toggle';
import { useChatbotStore } from '../store';
import { useUser } from '../providers/UserProvider';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import EmailSupportForm from './SupportForm';

//CONTACT_URL=`${process.env.REACT_APP_URL}/api/settings/${current_assistant}/contact`;
axios.defaults.withCredentials = true;
const Automations = () => {
  const { id } = useParams();
  const current_assistant = useChatbotStore((state) => state.current_assistant);
  const chatbot_name = useChatbotStore((state) => state.current_assistant_name);
  const isSubscribed = useChatbotStore((state) => state.isSubscribed);
  const subjects = [
    'Ideas forAutomations',
    'Feature Requests',
    'Bug Reports',
    'Other',
  ];
  const {
    result: { userId },
  } = useUser();
  const emailOptions = {
    ACTIVE: 1,
    INACTIVE: 0,
  };
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [selectedOption, setSelectedOption] = useState(emailOptions.INACTIVE);
  const [emailSettings, setEmailSettings] = useState({
    port: 465,
    server: '',
    username: '',
    password: '',
  });

  const loadSettings = async () => {
    const authToken = localStorage.getItem('psg_auth_token');
    if (!current_assistant || userId === undefined) return;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/settings/${current_assistant}/contact`,
        {
          headers: {
            Cookie: `psg_auth_token=${authToken}`,
          },
        }
      );
      console.log({ contact: response.data });
      setEmailSettings({
        port: response.data.port || 465,
        server: response.data.server,
        username: response.data.username,
        password: response.data.password,
      });
      setSelectedOption(
        response.data.active === emailOptions.ACTIVE
          ? emailOptions.ACTIVE
          : emailOptions.INACTIVE
      );
    } catch (error) {
      console.error('Error fetching settings', error);
    }
  };

  useEffect(() => {
    loadSettings();
  }, [current_assistant, userId]);

  const saveSettings = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    if (!current_assistant) return;

    if (
      !emailSettings.server ||
      !emailSettings.username ||
      !emailSettings.password
    ) {
      setError('Please fill in all fields');
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/settings/${current_assistant}/contact`,
        { ...emailSettings, active: selectedOption }
      );
      setMessage('Settings saved');
    } catch (error) {
      console.error('Error saving settings', error);
      setError('Error saving settings');
    }
  };

  return (
    <>
      <section className='py-12 bg-gray-900 min-h-screen'>
        <div className='container px-4 mx-auto'>
          <div className='w-full lg:w-1/2 px-4 mb-8 lg:mb-0'>
            <h1 className='text-4xl font-bold text-white mb-12'>
              Automate Chatbot
            </h1>
            <p className='text-2xl font-bold text-white mb-4 block'>
              {chatbot_name}
            </p>
            <div className='relative block'>
              <div className='w-full  pt-2 px-2 pb-2 text-start rounded-t-xl bg-gray-800 flex items-center justify-between'>
                <span className='text-xs font-semibold text-white'>Email</span>
                <Toggle
                  name='Active'
                  selected={selectedOption}
                  onChange={setSelectedOption}
                  options={[emailOptions.ACTIVE, emailOptions.INACTIVE]}
                />
              </div>
              <form onSubmit={saveSettings}>
                <div className='relative p-8 bg-gray-900 border border-gray-800 rounded-xl shadow-sm'>
                  <div className='text-start mb-8'>
                    <h5 className='text-xl font-medium text-white mb-4'>
                      Set up your email
                    </h5>
                  </div>
                  <div className='mb-4'>
                    <label
                      className='block mb-1 text-sm font-medium text-white'
                      htmlFor=''
                    >
                      Smtp Server
                    </label>
                    <input
                      className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                      type='text'
                      value={emailSettings.server}
                      onChange={(e) => {
                        setEmailSettings({
                          ...emailSettings,
                          server: e.target.value,
                        });
                      }}
                      placeholder='smtp server'
                    />
                  </div>
                  <div className='mb-4'>
                    <label
                      className='block mb-1 text-sm font-medium text-white'
                      htmlFor=''
                    >
                      Smtp Port
                    </label>
                    <input
                      className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                      type='number'
                      value={emailSettings.port}
                      onChange={(e) => {
                        setEmailSettings({
                          ...emailSettings,
                          port: Number(e.target.value),
                        });
                      }}
                      placeholder='465'
                    />
                  </div>
                  <div className='mb-4'>
                    <label
                      className='block mb-1 text-sm font-medium text-white'
                      htmlFor=''
                    >
                      Smtp Username
                    </label>
                    <input
                      className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                      type='text'
                      value={emailSettings.username}
                      onChange={(e) => {
                        setEmailSettings({
                          ...emailSettings,
                          username: e.target.value,
                        });
                      }}
                      placeholder='smtp username'
                    />
                  </div>
                  <div className='mb-4'>
                    <label
                      className='block mb-1 text-sm font-medium text-white'
                      htmlFor=''
                    >
                      Smtp Password
                    </label>
                    <input
                      className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                      type='password'
                      value={emailSettings.password}
                      onChange={(e) => {
                        setEmailSettings({
                          ...emailSettings,
                          password: e.target.value,
                        });
                      }}
                      placeholder='smtp password'
                      autocomplete='new-password'
                    />
                  </div>
                  <button
                    className={`group relative w-50 h-12 mb-8 flex items-center justify-center px-5 p-px font-bold text-gray-900 ${' text-gray-900 bg-yellowGreen-600'}  rounded-lg transition-all duration-300`}
                    type='submit'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Save</span>
                  </button>
                  <ul>
                    <li className='flex items-center mb-4'>
                      <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-800 rounded-full'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          {!message ? null : (
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='white'
                            />
                          )}
                        </svg>
                      </div>
                      <span className='text-white'>{message}</span>
                      <span className='text-red-500'>{error}</span>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <EmailSupportForm
        title='What would you like to seefor your next automation?'
        subjects={subjects}
        prefix='AUTOMATIONS-'
      />
    </>
  );
};

export default Automations;
