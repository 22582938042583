import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../providers/UserProvider';
axios.defaults.withCredentials = true;
const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Pricing() {
  const navigate = useNavigate();
  const location = useLocation();
  const pricing = { MONTHLY: 1, YEARLY: 2 };
  const [planId, setPlanId] = useState('');
  const plans = [
    {
      name: 'Starter',
      1: {
        price: process.env.REACT_APP_STARTER_MONTHLY,
        meta: 'STARTER_MONTHLY',
      },
      2: {
        price: process.env.REACT_APP_STARTER_ANNUAL,
        meta: 'STARTER_ANNUAL',
      },
    },
    {
      name: 'Premium',
      1: { price: process.env.REACT_APP_PRO_MONTHLY, meta: 'PRO_MONTHLY' },
      2: { price: process.env.REACT_APP_PRO_ANNUAL, meta: 'PRO_ANNUAL' },
    },
  ];
  const [selectedPricing, setSelectedPricing] = useState(pricing.MONTHLY);
  const {
    result: {
      username = '',
      userId = null,
      name = '',
      plan: userPlan = '',
      subscribed = false,
      customer_id = null,
    } = {},
  } = useUser() || {};

  // const createCustomer = async (redirect_url) => {
  //   const authToken = localStorage.getItem('psg_auth_token');
  //   try {
  //     const customer = await axios.post(
  //       `${process.env.REACT_APP_URL}/api/stripe/customer`,
  //       { email: username, name: name },
  //       {
  //         headers: {
  //           Cookie: `psg_auth_token=${authToken}`,
  //         },
  //         withCredentials: true,
  //       }
  //     );
  //     if (customer.status !== 200) {
  //       alert(
  //         'Failed to create customer, make sure your name is set in your profile'
  //       );
  //     } else {
  //       console.log({ customer });
  //       window.location.href = redirect_url + `&client_reference_id=${userId}`;
  //     }
  //   } catch (error) {
  //     console.error('Error creating customer:', error);
  //   }
  // };

  const checkOut = async (plan) => {
    if (location.pathname === '/pricing') {
      navigate('/auth');
      return;
    }
    const pricePlan = plans[plan][selectedPricing];
    console.log({ pricePlan });
    let response;
    if (subscribed) {
      const authToken = localStorage.getItem('psg_auth_token');
      response = await axios
        .post(
          `${process.env.REACT_APP_URL}/api/stripe/portal`,
          {
            customer_id: customer_id,
          },
          {
            headers: {
              Cookie: `psg_auth_token=${authToken}`,
            },
            withCredentials: true,
          }
        )
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Response data:', error.response.data);
            //console.error('Response status:', error.response.status);
            console.error('Response headers:', error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.error('No response:', error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error message:', error.message);
          }
          console.error('Config:', error.config);
        });
    } else {
      if (selectedPricing === pricing.LIFETIME) {
        window.location.href =
          plans[plan].lifetime_url +
          `?prefilled_email=${username}&client_reference_id=${userId}`;
        return;
      }
      const authToken = localStorage.getItem('psg_auth_token');
      response = await axios
        .post(
          `${process.env.REACT_APP_URL}/api/stripe/checkout`,
          {
            user_id: userId,
            customer_email: username || '',
            success_url: `${process.env.REACT_APP_URL}/account`,
            cancel_url: `${process.env.REACT_APP_URL}/pricing`,
            meta: pricePlan.meta,
            line_items: [
              {
                price: pricePlan.price,
                quantity: 1,
              },
            ],
          },
          {
            headers: {
              Cookie: `psg_auth_token=${authToken}`,
            },
            withCredentials: true,
          }
        )
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Response data:', error.response.data);
            //console.error('Response status:', error.response.status);
            console.error('Response headers:', error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.error('No response:', error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error message:', error.message);
          }
          console.error('Config:', error.config);
        });
    }
    if (response.status !== 200) {
      alert('Failed to create checkout session');
      navigate('/auth');
    }
    window.location.href = response.data.url;
  };

  useEffect(() => {
    if (!subscribed || !customer_id) return;
    if (userPlan.toLowerCase().includes('lifetime')) {
      setSelectedPricing(pricing.LIFETIME);
      return;
    }
    const getSubscription = async () => {
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/stripe/subscription`,
        { customer_id },
        {
          headers: {
            Cookie: `psg_auth_token=${authToken}`,
          },
          withCredentials: true,
        }
      );
      if (response.status !== 200) {
        alert('Failed to get subscription');
        //navigate('/auth');
      }

      const subscriptionTime =
        response.data.data[0].current_period_end -
        response.data.data[0].current_period_start;
      if (subscriptionTime < 2678500) {
        setSelectedPricing(pricing.MONTHLY);
      } else {
        setSelectedPricing(pricing.YEARLY);
      }
      setPlanId(response.data.data[0].plan.id);
    };
    getSubscription();
  }, [subscribed, userPlan]);

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section className='py-12 md:py-24 bg-gray-900 min-h-screen'>
        <div className='container px-4 mx-auto'>
          <div className='max-w-md lg:max-w-6xl mx-auto'>
            <div className='flex flex-wrap items-end -mx-4 mb-24'>
              <div className='w-full px-4 mb-10 lg:mb-0 lg:flex lg:justify-between'>
                <div className='max-w-sm sm:max-w-md'>
                  {!subscribed ? (
                    <>
                      <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white mb-4'>
                        Flexible pricing plan for your startup
                      </h1>
                      <p className='text-lg text-gray-400'>
                        Pricing that scales with your business immediately.
                      </p>
                    </>
                  ) : (
                    <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white mb-4'>
                      Manage your plan
                    </h1>
                  )}
                </div>
                {!subscribed && selectedPricing === pricing.LIFETIME && (
                  <div className='max-w-sm sm:max-w-md p-4 w-full flex flex-col'>
                    <button
                      className={`group relative w-full h-24 mb-8 flex items-center justify-center px-5 p-6  text-white hover:text-gray-900 font-bold text-2xl bg-gray-800 hover:bg-yellowGreen-600 border border-yellowGreen-600 rounded-lg transition-all duration-300`}
                      onClick={async () => {
                        await checkOut(0);
                      }}
                    >
                      <span>
                        <span className='text-xs'>Starter Plan</span>
                      </span>
                    </button>

                    <button
                      className={`group relative w-full h-24 mb-8 flex items-center justify-center px-5 p-6  text-white hover:text-gray-900 font-bold text-2xl bg-gray-800 hover:bg-yellowGreen-600 border border-yellowGreen-600 rounded-lg transition-all duration-300`}
                      onClick={async () => {
                        await checkOut(1);
                      }}
                    >
                      <span>
                        <span className='text-xs'>Pro Plan</span>
                      </span>
                    </button>
                  </div>
                )}
              </div>
              <div className='w-full px-4'>
                <div className='flex flex-col items-start lg:justify-end'>
                  <span className='font-medium text-white mb-2'>
                    Select Pricing:
                  </span>
                  <div className='flex flex-col items-start'>
                    {!userPlan.toLowerCase().includes('lifetime') && (
                      <>
                        <div className='flex items-center justify-start mb-2'>
                          <input
                            type='radio'
                            id='monthly'
                            name='pricingOption'
                            onChange={() => setSelectedPricing(pricing.MONTHLY)}
                            checked={selectedPricing === pricing.MONTHLY}
                            className='hidden'
                          />
                          <label
                            htmlFor='monthly'
                            className={`inline-block w-5 h-5 rounded-full border-2 border-white mr-2 ${
                              selectedPricing === pricing.MONTHLY
                                ? 'bg-yellowGreen-600'
                                : 'bg-transparent'
                            } focus:outline-none`}
                          />
                          <span
                            className={`text-white ${
                              selectedPricing === pricing.MONTHLY
                                ? 'font-bold'
                                : 'font-normal'
                            }`}
                          >
                            Monthly
                          </span>
                        </div>
                        <div className='flex items-start justify-start mb-2'>
                          <input
                            type='radio'
                            id='yearly'
                            name='pricingOption'
                            onChange={() => setSelectedPricing(pricing.YEARLY)}
                            checked={selectedPricing === pricing.YEARLY}
                            className='hidden'
                          />
                          <label
                            htmlFor='yearly'
                            className={`inline-block w-5 h-5 rounded-full border-2 border-white mr-2 ${
                              selectedPricing === pricing.YEARLY
                                ? 'bg-yellowGreen-600'
                                : 'bg-transparent'
                            } focus:outline-none`}
                          />
                          <span
                            className={`text-white ${
                              selectedPricing === pricing.YEARLY
                                ? 'font-bold'
                                : 'font-normal'
                            }`}
                          >
                            Yearly
                          </span>
                          <span className='inline-block ml-2 px-2 py-px bg-gray-800 text-xs text-white rounded-full'>
                            15% OFF
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${
                selectedPricing === pricing.LIFETIME ? 'hidden' : 'flex'
              } flex-wrap -mx-4`}
            >
              <div className='w-full lg:w-1/3 px-4 mb-18 lg:mb-0'>
                <div className='p-8 bg-gray-900 border border-gray-800 rounded-xl shadow-sm'>
                  <div className='text-center mb-8'>
                    <h5 className='text-xl font-medium text-white mb-4'>
                      Starter
                    </h5>
                    <div className='mb-4'>
                      {selectedPricing === pricing.MONTHLY ? (
                        <>
                          <span className='text-5xl font-semibold text-white tracking-tighter'>
                            $20.99
                          </span>
                          <span className='text-lg text-gray-400 font-medium'>
                            /month
                          </span>
                        </>
                      ) : (
                        <>
                          <span className='text-5xl font-semibold text-white tracking-tighter'>
                            $17.99
                          </span>
                          <span className='text-lg text-gray-400 font-medium'>
                            /month or $215.99/year
                          </span>
                        </>
                      )}
                    </div>
                    <span className='text-gray-400'>
                      Billed{' '}
                      {selectedPricing === pricing.MONTHLY
                        ? 'monthly'
                        : 'annually'}
                    </span>
                  </div>
                  <button
                    className={`group relative w-full h-12 mb-8 flex items-center justify-center px-5 p-px font-bold text-gray-900 ${
                      planId === plans[0][selectedPricing].price
                        ? ' text-white hover:text-gray-900 font-bold bg-gray-800 hover:bg-yellowGreen-600 border border-yellowGreen-600'
                        : ' text-gray-900 bg-yellowGreen-600'
                    }  rounded-lg transition-all duration-300`}
                    onClick={async () => {
                      await checkOut(0);
                    }}
                  >
                    <span>
                      {planId === plans[0][selectedPricing].price
                        ? 'Manage Your Plan'
                        : 'Get Started'}
                    </span>
                  </button>
                  <ul>
                    <li className='flex items-center mb-4'>
                      <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-800 rounded-full'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                            fill='white'
                          />
                        </svg>
                      </div>
                      <span className='text-white'>Embed on one site</span>
                    </li>
                    <li className='flex items-center mb-4'>
                      <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-800 rounded-full'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                            fill='white'
                          />
                        </svg>
                      </div>
                      <span className='text-white'>No Choony branding</span>
                    </li>
                    <li className='flex items-center'>
                      <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-800 rounded-full'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                            fill='white'
                          />
                        </svg>
                      </div>
                      <span className='text-white'>GPT 3.5+ & GPT 4 +</span>
                    </li>
                    <li className='flex items-center'>
                      <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-800 rounded-full'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                            fill='white'
                          />
                        </svg>
                      </div>
                      <span className='text-white'>Contact Automation</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='w-full lg:w-1/3 px-4 mb-8 lg:mb-0'>
                <div className='relative'>
                  <div className='absolute top-0 left-0 w-full -mt-10 pt-2 px-2 pb-14 text-center rounded-t-xl bg-gray-800'>
                    <span className='text-xs font-semibold text-white'>
                      RECOMMENDED
                    </span>
                  </div>
                  <div className='relative p-8 bg-gray-900 border border-gray-800 rounded-xl shadow-sm'>
                    <div className='text-center mb-8'>
                      <h5 className='text-xl font-medium text-white mb-4'>
                        Premium
                      </h5>
                      <div className='mb-4'>
                        {selectedPricing === pricing.MONTHLY ? (
                          <>
                            <span className='text-5xl font-semibold text-white tracking-tighter'>
                              $34.99
                            </span>
                            <span className='text-lg text-gray-400 font-medium'>
                              /month
                            </span>
                          </>
                        ) : (
                          <>
                            <span className='text-5xl font-semibold text-white tracking-tighter'>
                              $29.99
                            </span>
                            <span className='text-lg text-gray-400 font-medium'>
                              /month or $355.99/year
                            </span>
                          </>
                        )}
                      </div>
                      <span className='text-gray-400'>
                        Billed{' '}
                        {selectedPricing === pricing.MONTHLY
                          ? 'monthly'
                          : 'annually'}
                      </span>
                    </div>
                    <button
                      className={`group relative w-full h-12 mb-8 flex items-center justify-center px-5 p-px font-bold text-gray-900 ${
                        planId === plans[1][selectedPricing].price
                          ? ' text-white hover:text-gray-900 font-bold bg-gray-800 hover:bg-yellowGreen-600 border border-yellowGreen-600'
                          : ' text-gray-900 bg-yellowGreen-600'
                      }  rounded-lg transition-all duration-300`}
                      onClick={async () => {
                        await checkOut(1);
                      }}
                    >
                      <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                      <span>
                        {planId === plans[1][selectedPricing].price
                          ? 'Manage Your Plan'
                          : 'Get Started'}
                      </span>
                    </button>
                    <ul>
                      <li className='flex items-center mb-4'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-800 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='white'
                            />
                          </svg>
                        </div>
                        <span className='text-white'>
                          Embed in up to 3 sites
                        </span>
                      </li>
                      <li className='flex items-center mb-4'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-800 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='white'
                            />
                          </svg>
                        </div>
                        <span className='text-white'>No Choony branding</span>
                      </li>
                      <li className='flex items-center'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-800 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='white'
                            />
                          </svg>
                        </div>
                        <span className='text-white'>GPT3.5+ & GPT4+ </span>
                      </li>
                      <li className='flex items-center'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-800 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='white'
                            />
                          </svg>
                        </div>
                        <span className='text-white'>
                          Contact + 2 more automations
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='w-full lg:w-1/3 px-4'>
                <div className='p-8 bg-gray-900 border border-gray-800 rounded-xl shadow-sm'>
                  <div className='text-center mb-8'>
                    <h5 className='text-xl font-medium text-white mb-4'>
                      Enterprise
                    </h5>
                    <div className='mb-4'>
                      <span className='text-5xl font-semibold text-white tracking-tighter'>
                        Custom
                      </span>
                    </div>
                    <a
                      className='text-gray-400'
                      href='mailto:sales@choony.io?Subject=I am interested in the enterprise plan'
                    >
                      Please contact sales for details
                    </a>{' '}
                  </div>
                  <arguments
                    className='flex items-center justify-center w-full h-12 px-4 mb-8 text-center text-base text-white hover:text-gray-900 font-bold bg-gray-800 hover:bg-yellowGreen-600 border border-yellowGreen-600 rounded-lg transition duration-200'
                    href='mailto:sales@choony.io?Subject=I am interested in the enterprise plan'
                  >
                    Contact Sales
                  </arguments>
                  <ul>
                    <li className='flex items-center mb-4'>
                      <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-800 rounded-full'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                            fill='white'
                          />
                        </svg>
                      </div>
                      <span className='text-white'>Embed Anywhere*</span>
                    </li>
                    <li className='flex items-center mb-4'>
                      <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-800 rounded-full'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                            fill='white'
                          />
                        </svg>
                      </div>
                      <span className='text-white'>No Choony branding</span>
                    </li>
                    <li className='flex items-center'>
                      <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-800 rounded-full'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                            fill='white'
                          />
                        </svg>
                      </div>
                      <span className='text-white'>GPT or OS Models</span>
                    </li>
                    <li className='flex items-center'>
                      <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-800 rounded-full'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                            fill='white'
                          />
                        </svg>
                      </div>
                      <span className='text-white'>Teams</span>
                    </li>
                    <li className='flex items-center'>
                      <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-800 rounded-full'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                            fill='white'
                          />
                        </svg>
                      </div>
                      <span className='text-white'>Custom Automations</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
